import React, { useCallback, useEffect, useState } from 'react';
import { breakpoints } from '@naf/theme';
import { FullScreenHeader, FullScreenHeadingVariant } from '@naf/section-header';
import { AdvancedImage } from '@cloudinary/react';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';
import { GridCol } from '@naf/grid';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { useCloudinary } from '../../../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { useWindowResize } from '../../../../hooks/useWindowResize';
import { FullScreenHeaderWrapper } from './styles';
import { HeroWithTitleAndWideImageType } from '../../../../pages/DynamicApp/Hero/HeroWithTitleAndWideImage';
import BreadCrumb from '../../../breadcrumb/BreadCrumb';

export const TitleAndWideImageHero = ({
  hero: { header, image, mobileImage, ingress, overlay },
  breadCrumbs,
}: {
  hero: HeroWithTitleAndWideImageType;
  breadCrumbs?: BreadcrumbData[];
}) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const imagePublicId = image?.public_id || image?.publicId;
  const mobileImagePublicId = mobileImage?.public_id || mobileImage?.publicId;

  const [mainImage, setMainImage] = useState(
    (imagePublicId &&
      cld
        .image(imagePublicId)
        .resize(fill().width(1120).gravity(autoGravity()))
        .delivery(dpr(devicePixelRatio))
        .quality('auto:best')
        .format('auto')) ||
      undefined,
  );

  const setResponsiveImage = useCallback(() => {
    const heroImage = window.innerWidth <= parseInt(breakpoints.s, 10) && mobileImagePublicId ? mobileImage : image;
    const heroImagePublicId = heroImage?.public_id || heroImage?.publicId;
    if (heroImagePublicId && typeof window !== 'undefined') {
      // Use vheight for small screens, and vw for large screens
      if (window.innerWidth <= parseInt(breakpoints.m, 10)) {
        const height = Math.round(window.innerHeight / devicePixelRatio);
        setMainImage(
          cld
            .image(heroImagePublicId)
            .resize(fill().height(height).gravity(autoGravity()))
            .delivery(dpr(devicePixelRatio))
            .quality('auto:best')
            .format('auto'),
        );
      } else {
        const width = Math.round(window.innerWidth / devicePixelRatio);
        setMainImage(
          cld
            .image(heroImagePublicId)
            .resize(fill().width(width).gravity(autoGravity()))
            .delivery(dpr(devicePixelRatio))
            .quality('auto:best')
            .format('auto'),
        );
      }
    }
  }, [mobileImagePublicId, mobileImage, image, devicePixelRatio, cld]);

  useEffect(() => {
    setResponsiveImage();
  }, [setResponsiveImage]);

  useWindowResize(() => {
    setResponsiveImage();
  });

  if (mainImage) {
    return (
      <FullScreenHeaderWrapper>
        {breadCrumbs && breadCrumbs.length > 1 ? (
          <BreadcrumbWrapper>
            <GridCol>
              <BreadCrumb />
            </GridCol>
          </BreadcrumbWrapper>
        ) : null}
        <FullScreenHeader
          imageOrVideo={<AdvancedImage cldImg={mainImage} alt={image.alt || image.altText || image.caption} />}
          subText={ingress}
          title={header}
          headingVariant={FullScreenHeadingVariant.Display}
          nafBranded={false}
          overlay={overlay}
        />
      </FullScreenHeaderWrapper>
    );
  }
  return <FullScreenHeader nafBranded subText={ingress} title={header} />;
};

const BreadcrumbWrapper = styled.div`
  position: absolute;
  margin: 0 auto;
  padding: 0 48px;
  top: 90px;
  max-width: 1120px;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  @media (max-width: ${breakpoints.m}) {
    top: 60px;
    padding: 0 24px;
  }
  * {
    color: ${nafColor.signature.white} !important;
    text-decoration-color: ${nafColor.signature.white};
  }
`;
